import React from "react";

const index = () => {
  return (
    <div className=" w-full bg-primary_color h-[calc(100vh-120px)]">
      <h1>Sorry page not found</h1>
    </div>
  );
};

export default index;
