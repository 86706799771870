export function ArrowDown(props: any) {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_345_857)">
        <path
          d="M13.8932 0.268032C13.715 0.445462 13.6143 0.686161 13.613 0.937589C13.6117 1.18902 13.7099 1.43075 13.8862 1.61003L18.3212 6.05003H1.44219C1.19023 6.05003 0.948596 6.15012 0.770436 6.32828C0.592277 6.50644 0.492188 6.74808 0.492188 7.00003C0.492188 7.25199 0.592277 7.49362 0.770436 7.67178C0.948596 7.84994 1.19023 7.95003 1.44219 7.95003H18.3132L13.8782 12.39C13.7036 12.5702 13.6065 12.8116 13.6078 13.0625C13.6091 13.3133 13.7087 13.5537 13.8852 13.732C14.0633 13.9079 14.3039 14.0059 14.5541 14.0046C14.8044 14.0033 15.044 13.9027 15.2202 13.725L21.2302 7.67303C21.3134 7.58514 21.3811 7.48368 21.4302 7.37303C21.4797 7.25778 21.5046 7.13346 21.5032 7.00803C21.5031 6.76028 21.4062 6.52237 21.2332 6.34503L15.2232 0.293032C15.1383 0.202668 15.0362 0.130201 14.9229 0.0799037C14.8096 0.0296059 14.6874 0.00249386 14.5635 0.000164088C14.4395 -0.00216569 14.3164 0.0203344 14.2012 0.0663386C14.0861 0.112343 13.9814 0.180921 13.8932 0.268032Z"
          fill="#031023"
        />
      </g>
      <defs>
        <clipPath id="clip0_345_857">
          <rect
            width="21"
            height="14"
            fill="white"
            transform="translate(0.492188)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
