import React from "react";

const index = () => {
  return (
    <div className=" bg-orange-500">
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
      <h1>register page</h1>
    </div>
  );
};

export default index;
