import React from "react";

const index = () => {
  return (
    <div>
      <div className=" bg-red-600">welcome to admin</div>
    </div>
  );
};

export default index;
